import React from 'react'
import { graphql } from 'gatsby'
import { HomePage } from '../components/home/Index/index'
import filterTextsData from '../utils/filterTextsData'

const Indexpage_EN = ({ data }) => {
	const texts = filterTextsData(data)

	return <HomePage texts={texts} lang='en' />
}

export default Indexpage_EN

export const query = graphql`
{
	allFile(filter: {name: {eq: "home(en)"}}) {
		edges {
			node {
				internal {
					content
				}
			}
		}
	}
}
`